<!--
  -- Created by zed on 2022/1/17
  -- Copyright © 2017 深圳市萌盟网络科技有限公司. All rights reserved.
  --
  -- 【隐私政策】
-->
<template>
  <div class="ad-protocol">
    <ad-navs :fixed="false"></ad-navs>

    <div class="content-container">
      <h2 class="title">{{ title }}</h2>
      <div class="main" v-html="content" />
    </div>

    <ad-footer style="margin-top: 100px" />
  </div>
</template>

<script>
import enterpriseApi from '@/api/enterprise'

export default {
  name: "AdProtocolComponent",
  data() {
    return {
      title: null,
      content: null,
    }
  },
  created() {
    this.getPrivacy()
  },
  methods: {
    async getPrivacy() {
      let { code, data } = await enterpriseApi.readPrivacy()
      this.title = data.name
      this.content = data.content
      // console.log('隐私协议：', this.content)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-protocol {
    padding-top: 50px !important;
    .content-container {
      max-width: 100% !important;
      padding: 20px !important;
      border-radius: 0 !important;
      .title {
        width: 100% !important;
        padding: 10px 0 20px 0 !important;
        font-size: 18px !important;
      }
      .main {
        width: 100% !important;
        p {
          font-size: 13px !important;
        }
      }
    }
  }
}
.ad-protocol {
  width: 100%;
  padding-top: 130px;
  box-sizing: border-box;
  background: #f6f9fa;
  .content-container {
    max-width: 1180px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0 auto;
    padding-bottom: 100px;
    .title {
      width: 948px;
      margin: 0 auto;
      font-size: 30px;
      font-weight: 600;
      color: #222222;
      line-height: 42px;
      padding: 100px 0 59px 0;
    }
    .main {
      width: 948px;
      margin: 0 auto;
      white-space: pre-wrap;

      ::v-deep p {
        font-size: 1.1rem;
        line-height: 2rem;
        font-weight: 400;
        color: #222222;
        white-space: pre-line;
        overflow: hidden;
      }
    }
  }
}
</style>
